import axios from 'axios'
import Vuex from 'vuex'
import slider from './store/slider'
import homeSale from './store/homeSale'
import homeNew from './store/homeNew'
import homeAbout from './store/homeAbout'
import section from './store/section'
import basket from './store/basket'
import order from './store/order'
import personal from './store/personal'
import company from './store/company'
import refund from './store/refund'

export default new Vuex.Store({
    state: {
        error: null,
        isAuth: false, //localStorage.getItem('user-token')?true:false,
        login: '',
        user: '',
        phone: '',
        loader: false,
        uuid: '',
        config: {
            headers:{
                token: localStorage.getItem('user-token')
            }
        }
    },
    mutations: {
        Auth(state, data) {
            // debugger;
            state.isAuth = data.is_auth;
            localStorage.setItem('user-token', data.token);
            state.login = data.login
            state.user = data.user
            state.phone = data.phone
        },
        Loader(state, data) {
            state.loader = true;
        },
        unLoader(state, data) {
            state.loader = false;
        },
        setError(state, error) {
            state.error = error
        },
        clearError(state) {
            state.error = null
        },
        Uuid(state, data) {
            state.uuid = data
        },
        Logout(state, data) {
            //state.isAuth = false;
            state.user = false;
            state.phone = data.phone;
            //localStorage.setItem('user-token', '');
            //state.login = data.login
        },
    },
    actions: {
        async Auth({commit, dispatch}, data) {
            commit('Auth', data);
            if (typeof window.FirebasePlugin != 'undefined') {
                window.FirebasePlugin.getToken(async function (device_token) {
                    axios.post('/local/api/token', {
                        token: device_token
                    });
                    /*
                    let response = await fetch('/local/ajax/token.php?token='+device_token+'&uuid='+_this.device_uuid); // завершается с заголовками ответа
                    let result = await response.json(); // читать тело ответа в формате JSON*/
                }, function (error) {
                    //alert('error')
                });
            }
        },
        Loader({commit, dispatch}, data) {
            commit('Loader');
        },
        unLoader({commit, dispatch}, data) {
            commit('unLoader');
        },
        Uuid({commit, dispatch}, data) {
            commit('Uuid', data);
        },
        async Logout({commit, dispatch}, data) {
            commit('Logout', data);
        },
    },
    getters: {
        error: s => s.error,
        isAuth: s => s.isAuth,
        login: s => s.login,
        user: s => s.user,
        loader: s => s.loader,
        uuid: s => s.uuid,
        config: s => s.config,
        phone: s => s.phone,
        manager: s => s.user.manager
    },
    modules: {
        slider,
        homeSale,
        homeNew,
        homeAbout,
        section,
        basket,
        order,
        personal,
        company,
        refund
    }
})
