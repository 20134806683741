<template>
  <div class="products-list">
    <div v-for="(brand, index) in brands" class="products-list__block">
      <a class="products-list__block-head" v-if="brand.id"
         :class="{'products-list__block-head--open':show[index], 'products-list__block-head--sale': type=='sale', 'products-list__block-head--new': type=='new'}"
         @click="showProducts(brand.id, index)"
      >
        <div class="products-list__block-img-wrapper">
          <img v-if="brand.image" class="products-list__block-img" :src="brand.image" :alt="brand.name">
        </div>
        <h2 class="products-list__block-heading">{{brand.name}}</h2>
        <span class="products-list__block-counter">{{brand.count}} {{ declOfNum(brand.count, ['товар','товара','товаров']) }}</span>
      </a>
      <div class="products-list__products" v-show="brand.show">
        <div v-if="brand.open" v-for="product in products(brand.id)" class="products-list__product product">
          <ProductItem :item="product"></ProductItem>
        </div>
        <div v-if="brand.show && !brand.open">Загрузка...</div>
      </div>
    </div>
  </div>
</template>
<script>

import ProductItem from "@/components/app/ProductItem";
export default {
  props: {
    type: '',
    brands: '',
    id: '',
    sort: '',
    search: ''
  },
  data: () => ({
    open: [],
    show: []
  }),
  watch: {
    sort() {
      this.open = [];
      this.show = [];
    },
    search() {
      this.open = [];
      this.show = [];
    },
  },
  computed: {
    products() {
      return (brand_id) => {
          return this.$store.getters.products(this.$props.id, brand_id);
      }
    }
  },
  methods: {
    async showProducts(brand_id, index) {
      this.brands[index].show=!this.brands[index].show
      await this.$store.dispatch({
        type:'fetchProducts',
        section_id:this.$props.id,
        brand_id:brand_id,
        sort: this.$props.sort,
        search: this.$props.search
      })
      this.brands[index].open=!this.brands[index].open
    },
    declOfNum(n, text_forms) {
      n = Math.abs(n) % 100;
      let n1 = n % 10;
      if (n > 10 && n < 20) { return text_forms[2]; }
      if (n1 > 1 && n1 < 5) { return text_forms[1]; }
      if (n1 == 1) { return text_forms[0]; }
      return text_forms[2];
    }
  },
  components: {
    ProductItem

  }
}
</script>